import Column from 'components/column';
import Link from 'components/link';
import Section from 'components/section';
import { graphql } from 'gatsby';
import LayoutContext from 'layouts/reducer';
import React from 'react';

import * as styles from './post-list.module.scss';

const ListComponent: React.FC<{
  postLinkText: string;
  posts: GatsbyTypes.PostListFragment['posts'];
}> = ({ postLinkText, posts }) => {
  const { state } = React.useContext(LayoutContext);

  if (!posts || posts.length <= 0) return null;

  if (!state.isSmall)
    return (
      <>
        {posts.map(
          (post) =>
            post && (
              <Column className={styles.postItem} key={post.id}>
                <h4>
                  <Link
                    arrow={'right'}
                    link={{
                      model: { apiKey: post.model?.apiKey as string },
                      slug: post.slug as string,
                    }}
                  >
                    {post.title}
                  </Link>
                </h4>
              </Column>
            )
        )}
      </>
    );

  return (
    <>
      {posts.map(
        (post) =>
          post && (
            <Column className={styles.postItem} key={post.id}>
              <Link
                link={{
                  model: { apiKey: post.model?.apiKey as string },
                  slug: post.slug as string,
                }}
              >
                <h4>{post.title}</h4>
              </Link>
              <Link
                arrow={'right'}
                link={{
                  model: { apiKey: post.model?.apiKey as string },
                  slug: post.slug as string,
                }}
              >
                {postLinkText}
              </Link>
            </Column>
          )
      )}
    </>
  );
};

const PostList: React.FC<GatsbyTypes.PostListFragment> = ({
  title,
  description,
  postLinkText,
  bottomColumnLeft,
  bottomColumnRight,
  posts,
  ...props
}) => {
  return (
    <Section className={styles.section} {...props}>
      <Column
        className={styles.container}
        xl={{ offset: 3, width: 8 }}
        lg={{ offset: 3, width: 8 }}
        md={{ offset: 1, width: 10 }}
      >
        <Column className={styles.containerText} xl={6} lg={7} md={7} sm={7}>
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: description as string }} />
        </Column>
        {posts && <ListComponent postLinkText={postLinkText as string} posts={posts} />}
        <Column className={styles.containerBottom}>
          <Column
            dangerouslySetInnerHTML={{ __html: bottomColumnLeft as string }}
            xl={6}
            lg={6}
            md={6}
            sm={5}
          />
          <Column
            dangerouslySetInnerHTML={{ __html: bottomColumnRight as string }}
            xl={6}
            lg={6}
            md={6}
            sm={7}
          />
        </Column>
      </Column>
    </Section>
  );
};

export default PostList;

export const query = graphql`
  fragment PostList on DatoCmsPostList {
    __typename
    id: originalId
    title
    description
    postLinkText
    bottomColumnLeft
    bottomColumnRight
    posts {
      id
      title
      slug
      model {
        apiKey
      }
    }
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
  }
`;
