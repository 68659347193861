// extracted by mini-css-extract-plugin
export var large = "news-selection-module--large--sfvoC";
export var newsSelection = "news-selection-module--newsSelection--DBGrU";
export var newsSelectionCarousell = "news-selection-module--newsSelectionCarousell--M-fNr";
export var newsSelectionContent = "news-selection-module--newsSelectionContent--pcLNI";
export var newsSelectionContentTCneon = "news-selection-module--newsSelectionContentTCneon--JeUAW";
export var newsSelectionContentTCblue = "news-selection-module--newsSelectionContentTCblue--ipXWZ";
export var newsSelectionBGlight = "news-selection-module--newsSelectionBGlight--J1uVZ";
export var link = "news-selection-module--link--hU+8p";
export var newsSelectionBGmint = "news-selection-module--newsSelectionBGmint--Qr5W4";
export var newsSelectionBGblue = "news-selection-module--newsSelectionBGblue--XBSPs";
export var newsSelectionBGneon = "news-selection-module--newsSelectionBGneon--MSG2P";
export var newsSelectionBGconcrete = "news-selection-module--newsSelectionBGconcrete--NK-tA";
export var column = "news-selection-module--column--HXqEn";
export var scrollContainer = "news-selection-module--scrollContainer--+HnXG";
export var scrollButton = "news-selection-module--scrollButton--ma3wf";