// extracted by mini-css-extract-plugin
export var large = "image-text-cta-module--large--ijALw";
export var wrapper = "image-text-cta-module--wrapper--m8-SS";
export var imageColumn = "image-text-cta-module--imageColumn--NBrxP";
export var image = "image-text-cta-module--image--+eaBt";
export var textColumn = "image-text-cta-module--textColumn--dWpTS";
export var textColumnFigure = "image-text-cta-module--textColumnFigure--k+GFL";
export var linkText = "image-text-cta-module--linkText--QyJ01";
export var link = "image-text-cta-module--link--U43x3";
export var linkArrow = "image-text-cta-module--linkArrow--InWDR";
export var textColumnBGblue = "image-text-cta-module--textColumnBGblue--FEXDE";
export var textColumnBGmint = "image-text-cta-module--textColumnBGmint---JCL9";
export var textColumnBGlight = "image-text-cta-module--textColumnBGlight--k5+Ii";
export var textColumnBGwhite = "image-text-cta-module--textColumnBGwhite--mlmak";
export var textColumnBGneon = "image-text-cta-module--textColumnBGneon--cLJQW";