import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './logotypes-section.module.scss';

type LogotypesSectionProps = GatsbyTypes.LogotypesSectionFragment;

const LogotypesSection: React.FC<LogotypesSectionProps> = ({ images, ...props }) => {
  return (
    <Section {...props}>
      <Column className={styles.imageList}>
        {images?.map((image, index) => (
          <Image
            key={`${image?.url}-${index}`}
            objectFit={'contain'}
            className={styles.imageListItem}
            {...image}
          />
        ))}
      </Column>
    </Section>
  );
};

export default LogotypesSection;

export const query = graphql`
  fragment LogotypesSection on DatoCmsLogotypesSection {
    __typename
    id: originalId
    backgroundColor
    images {
      ...Image
    }
  }
`;
