import React from 'react';
import Section from 'components/section';
import { graphql, useStaticQuery } from 'gatsby';
import CardCarousel, {
  newsToCards,
  CardCarouselHandle,
  OnPageChangeArgs,
} from 'sections/card-carousel';
import Link, { LinkType } from 'components/link';
import clsx from 'clsx';
import { CardProps } from 'components/card';
import Column from 'components/column';
import Arrow from 'components/arrow';

import * as styles from './news-feed.module.scss';
import LayoutContext from 'layouts/reducer';

type NewsFeedProps = Partial<GatsbyTypes.DatoCmsNewsFeed> & {
  data?: GatsbyTypes.DatoCmsNews[];
  className?: string;
};

const NewsFeed: React.FC<NewsFeedProps> = ({ data = [], className, ...props }) => {
  const {
    allDatoCmsNews: { news },
  } = useStaticQuery<GatsbyTypes.NewsFeedQueryQuery>(graphql`
    query NewsFeedQuery {
      allDatoCmsNews(limit: 12, sort: { fields: meta___firstPublishedAt, order: DESC }) {
        news: nodes {
          id: originalId
          title
          slug
          meta {
            publishedAt: firstPublishedAt(formatString: "YYYY-MM-DD")
          }
          category {
            title
          }
          model {
            apiKey
          }
          featuredImage {
            ...Image
          }
        }
      }
    }
  `);
  const { state } = React.useContext(LayoutContext);
  const [cardItems, setCardItems] = React.useState<CardProps[]>([]);
  const [{ currentPage, totalPages, canScrollNext, canScrollPrev }, setCarouselDetails] =
    React.useState<OnPageChangeArgs>({
      currentPage: 0,
      totalPages: 0,
    });
  const ref = React.useRef<CardCarouselHandle>(null);

  React.useEffect(() => {
    if (cardItems.length > 0) return;

    if (data.length > 0) {
      setCardItems(newsToCards(data as GatsbyTypes.DatoCmsNews[]));
    } else {
      setCardItems(newsToCards(news as GatsbyTypes.DatoCmsNews[]));
    }
  }, [cardItems.length, data, news]);

  return (
    <Section
      className={clsx(
        className,
        styles.newsFeed,
        (styles as never)[`newsFeedBG${props.backgroundColor}`]
      )}
      sectionClass={styles.newsFeedSection}
      backgroundColor={props.backgroundColor}
      topBackgroundColor={props.topBackgroundColor}
      topBackgroundOffset={props.topBackgroundOffset}
    >
      {cardItems.length > 0 && (
        <CardCarousel
          ref={ref}
          perPage={4}
          scrollCount={state.isLarge ? 4 : state.isSmall ? 3 : 1}
          progressClassName={styles.progressBar}
          backgroundColor={props.backgroundColor}
          onPageChange={setCarouselDetails}
          data={cardItems}
        />
      )}
      <Column className={styles.column}>
        <div className={styles.scrollContainer}>
          {totalPages > 1 && (
            <>
              <button
                className={styles.scrollButton}
                onClick={ref.current?.scrollPrev}
                disabled={!canScrollPrev}
              >
                <Arrow dir={'left'} />
              </button>
              {currentPage + 1}/{totalPages}
              <button
                className={styles.scrollButton}
                onClick={ref.current?.scrollNext}
                disabled={!canScrollNext}
              >
                <Arrow dir={'right'} />
              </button>
            </>
          )}
        </div>
        <h3>
          <Link className={styles.link} arrow={'right'} link={props.link as LinkType}>
            {props.linkText}
          </Link>
        </h3>
      </Column>
    </Section>
  );
};

export default NewsFeed;

export const query = graphql`
  fragment NewsFeed on DatoCmsNewsFeed {
    __typename
    id: originalId
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
    linkText
    link {
      ...LinkCollection
    }
  }
`;
