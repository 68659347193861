import clsx from 'clsx';
import Column from 'components/column';
import Image from 'components/image';
import MatrixFigure from 'components/matrix-figure';
import Section, { SectionProps } from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './list-section.module.scss';

type ListItemProps = GatsbyTypes.ListItemFragment & {
  number: string;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({ className, title, number, text }) => (
  <Column className={clsx(className, styles.listItem)}>
    <Column className={styles.listTitleColumn} xl={6} lg={6} md={6} sm={6}>
      <sup>
        {number.padStart(2, '0')}
        {'.'}
      </sup>
      {title && <h2 className={styles.listItemTitle} dangerouslySetInnerHTML={{ __html: title }} />}
    </Column>
    <Column xl={6} lg={6} md={6} sm={6}>
      <div dangerouslySetInnerHTML={{ __html: text as string }} />
    </Column>
  </Column>
);

type ListSectionProps = Partial<GatsbyTypes.ListSectionFragment> &
  SectionProps<'section'> & {
    insetColumn?: boolean;
  };

const ListSection: React.FC<ListSectionProps> = ({
  title,
  image,
  backgroundColorContainer,
  listItems,
  insetColumn = false,
  ...props
}) => {
  return (
    <Section className={styles.section} {...props}>
      <Section {...props} as={'div'}>
        <Column
          className={clsx(
            styles.listContainer,
            (styles as never)[`listContainerBG${backgroundColorContainer}`]
          )}
          xl={{ width: 10, offset: 1 }}
          lg={{ width: 10, offset: 1 }}
          md={{ width: 10, offset: 1 }}
        >
          {image && <MatrixFigure className={styles.listFigure} size={'small'} />}
          {image && <Image className={styles.listImage} {...image} />}
          <Column className={styles.listContent}>
            <p className={styles.listContentTitle}>{title}</p>
            {listItems?.map(
              (item, index) =>
                item && (
                  <ListItem
                    className={(styles as never)[`listItemBG${backgroundColorContainer}`]}
                    key={item?.id}
                    number={(index + 1).toString()}
                    {...item}
                  />
                )
            )}
          </Column>
        </Column>
      </Section>
      {insetColumn && (
        <Column className={styles.listInsetColumn}>
          <Column xl={4} lg={4} md={4} sm={2} xs={1} />
        </Column>
      )}
    </Section>
  );
};

export default ListSection;

export const query = graphql`
  fragment ListSection on DatoCmsListSection {
    __typename
    id: originalId
    image {
      ...Image
    }
    listItems {
      ...ListItem
    }
    title
    backgroundColorContainer
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
  }
`;

export const listItemsFragment = graphql`
  fragment ListItem on DatoCmsListItem {
    id
    text
    title
  }
`;
