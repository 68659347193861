import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import React from 'react';
import ArrowRight from 'images/svg/arrow-left.inline.svg';
import clsx from 'clsx';
import Link, { LinkType } from 'components/link';
import stickybits from 'stickybits';

import * as styles from './service-section.module.scss';
import { graphql } from 'gatsby';
import MatrixFigure from 'components/matrix-figure';

const ServiceSectionItem: React.FC<GatsbyTypes.DatoCmsServiceItem> = ({ link = [], ...props }) => {
  const leftImg = /left/i.test(props.imagePosition as string);

  const serviceLink = link.length > 0 && (link as GatsbyTypes.DatoCmsLink[])[0];

  const TextColumn = () => (
    <div className={styles.content}>
      <div dangerouslySetInnerHTML={{ __html: props.text as string }} />
      <div>
        {serviceLink && (
          <p className={styles.link}>
            <Link
              externalUrl={serviceLink.externalUrl}
              link={serviceLink.link as LinkType}
              arrow={'right'}
            >
              {serviceLink.text}
            </Link>
          </p>
        )}
      </div>
    </div>
  );
  const ImgColumn = () => (
    <div className={styles.image}>
      {props.showFigure && (
        <MatrixFigure
          className={clsx(styles.imageFigure, { [styles.imageFigureLeft]: leftImg })}
          size={'small'}
        />
      )}
      <Image className={styles.imageItem} {...props.image} />
    </div>
  );
  return (
    <Column
      className={clsx(
        styles.serviceItem,
        (styles as never)[`serviceItemImg${props.imagePosition}`]
      )}
      xl={!leftImg ? { width: 11, offset: 1 } : 12}
    >
      {leftImg && <ImgColumn />}
      <TextColumn />
      {!leftImg && <ImgColumn />}
    </Column>
  );
};

const ServiceSection: React.FC<GatsbyTypes.DatoCmsServiceSection> = ({
  title,
  serviceItems = [],
  ...props
}) => {
  if (typeof window !== 'undefined') stickybits(`${styles.stickyItemWrapper}`);

  return (
    <Section className={styles.section} {...props}>
      <Column className={styles.stickyContainer} xl={1} lg={1} md={1} sm={2} xs={2}>
        <div className={styles.stickyItem}>
          <div className={styles.stickyItemWrapper}>
            <h2 className={styles.placeholder}>
              <ArrowRight className={styles.stickyItemArrow} />
              {title}
            </h2>
            <h2 className={styles.mockup}>
              <ArrowRight className={styles.stickyItemArrow} />
              {title}
            </h2>
          </div>
        </div>
      </Column>
      <Column
        className={clsx(styles.items, props.backgroundColor === 'white' && styles.itemsWhite)}
        xl={11}
        lg={11}
        md={11}
        sm={10}
        xs={10}
      >
        {(serviceItems as GatsbyTypes.DatoCmsServiceItem[]).map((item, index) => (
          <ServiceSectionItem key={item.id} {...item} />
        ))}
      </Column>
    </Section>
  );
};

export default ServiceSection;

export const query = graphql`
  fragment ServiceSection on DatoCmsServiceSection {
    __typename
    id: originalId
    title
    serviceItems {
      id: originalId
      title
      imagePosition
      text
      image {
        isImage
        mimeType
        gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "1.6", fit: "crop" })
        alt
        url
      }
      showFigure
      link {
        text
        externalUrl
        link {
          ...LinkCollection
        }
      }
    }
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
  }
`;
