// extracted by mini-css-extract-plugin
export var section = "list-section-module--section--xOg1s";
export var listFigure = "list-section-module--listFigure--XpZl1";
export var listContainer = "list-section-module--listContainer--PLVbk";
export var listContainerBGmint = "list-section-module--listContainerBGmint--+dpp8";
export var listContainerBGblue = "list-section-module--listContainerBGblue--PpN4h";
export var listContainerBGwhite = "list-section-module--listContainerBGwhite--aCMak";
export var listTitleColumn = "list-section-module--listTitleColumn--NCNfu";
export var listInsetColumn = "list-section-module--listInsetColumn--tvxKW";
export var listImage = "list-section-module--listImage--GtrZS";
export var listContent = "list-section-module--listContent--6K783";
export var listContentTitle = "list-section-module--listContentTitle--vi8us";
export var listItem = "list-section-module--listItem--qe+91";
export var listItemTitle = "list-section-module--listItemTitle--b8614";
export var listItemBGwhite = "list-section-module--listItemBGwhite--BIorF";
export var listItemBGmint = "list-section-module--listItemBGmint--79ErA";
export var listItemBGblue = "list-section-module--listItemBGblue--G291m";