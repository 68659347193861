import Arrow from 'components/arrow';
import Column from 'components/column';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './graphic-header.module.scss';

const GraphicHeader: React.FC<GatsbyTypes.DatoCmsGraphicHeader> = ({ text, ...props }) => {
  return (
    <Section className={styles.section} {...props}>
      <Column className={styles.arrowColumn} xl={5} lg={6} md={6} sm={6}>
        <Arrow
          className={styles.arrow}
          dir={'bottomRight'}
          color={props.backgroundColor === 'White' ? 'Blue' : 'Neon'}
        />
      </Column>
      <Column className={styles.textColumn} xl={{ width: 6, offset: 1 }} lg={6} md={6} sm={6}>
        <h1 dangerouslySetInnerHTML={{ __html: text as string }} />
      </Column>
    </Section>
  );
};

export default GraphicHeader;

export const query = graphql`
  fragment GraphicHeader on DatoCmsGraphicHeader {
    __typename
    id: originalId
    backgroundColor
    text
  }
`;
