// extracted by mini-css-extract-plugin
export var large = "news-feed-module--large--Z4k6W";
export var newsFeed = "news-feed-module--newsFeed--+TjkR";
export var newsFeedSection = "news-feed-module--newsFeedSection--rWfKI";
export var link = "news-feed-module--link--Gd-77";
export var column = "news-feed-module--column--yk27Y";
export var scrollContainer = "news-feed-module--scrollContainer--lsDIG";
export var scrollButton = "news-feed-module--scrollButton--YuLzc";
export var progressBar = "news-feed-module--progressBar--AlCWw";
export var newsFeedBGlight = "news-feed-module--newsFeedBGlight--OExZd";
export var newsFeedBGmint = "news-feed-module--newsFeedBGmint--EV3ty";
export var newsFeedBGblue = "news-feed-module--newsFeedBGblue--to0Zc";
export var newsFeedBGneon = "news-feed-module--newsFeedBGneon--7cLBB";
export var newsFeedBGconcrete = "news-feed-module--newsFeedBGconcrete--4OISJ";