import clsx from 'clsx';
import Image from 'components/image';
import Link, { LinkType } from 'components/link';
import MatrixFigure from 'components/matrix-figure';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './large-accordion.module.scss';

type LargeAccordionItemProps = GatsbyTypes.LargeAccordionItemFragment & {
  isOpen: boolean;
  onClick: (id: string) => void;
};

const LargeAccordionItem: React.FC<LargeAccordionItemProps> = ({
  id,
  title,
  image,
  text,
  link,
  isOpen,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick(id ?? '')}
      className={clsx(styles.accordionItem, { [styles.accordionItemOpen]: isOpen })}
    >
      <label>
        <h2>{title}</h2>
      </label>
      <div className={styles.accordionItemContent}>
        <MatrixFigure
          className={styles.accordionItemContentFigure}
          isVisible={isOpen}
          size={'small'}
        />
        <h4 className={styles.accordionItemContentTitle}>{title}</h4>
        {text && (
          <div
            className={styles.accordionItemContentText}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        {link && (
          <Link
            className={styles.accordionItemContentLink}
            arrow={'right'}
            link={link[0]?.link as LinkType}
          >
            {link[0]?.text}
          </Link>
        )}
      </div>
      <Image className={styles.accordionItemImage} {...image} />
    </div>
  );
};

const LargeAccordion: React.FC<Pick<GatsbyTypes.DatoCmsLargeAccordion, 'items'>> = ({ items }) => {
  const [openId, setOpenId] = React.useState<string>(items ? items[0]?.id ?? '' : '');

  return (
    <section className={styles.largeAccordion}>
      {items &&
        items.map(
          (accordionItem, index) =>
            accordionItem && (
              <LargeAccordionItem
                key={accordionItem?.id}
                isOpen={openId === accordionItem?.id}
                onClick={(id) => setOpenId(id)}
                {...accordionItem}
              />
            )
        )}
    </section>
  );
};

export default React.memo(LargeAccordion);

export const query = graphql`
  fragment LargeAccordionItem on DatoCmsLargeAccordionItem {
    id: originalId
    title
    image {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "1", fit: "crop" })
      alt
      url
    }
    text
    link {
      ...Link
    }
  }
  fragment LargeAccordion on DatoCmsLargeAccordion {
    __typename
    id: originalId
    items {
      ...LargeAccordionItem
    }
  }
`;
