import Arrow from 'components/arrow';
import Column from 'components/column';
import Link, { LinkType } from 'components/link';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './large-link.module.scss';

const LargeLink: React.FC<GatsbyTypes.DatoCmsLargeLinkCta> = ({
  text,
  externalUrl = '',
  link,
  ...props
}) => (
  <Section className={styles.section} {...props}>
    <Link className={styles.link} externalUrl={externalUrl} link={link as LinkType}>
      <Column className={styles.content} xl={9} lg={8} md={8} sm={8}>
        <h2 className={'large'}>{text}</h2>
      </Column>
      <Column xl={3} lg={4} md={4} sm={4}>
        <Arrow className={styles.arrow} dir={'largeRight'} />
      </Column>
    </Link>
  </Section>
);

export default LargeLink;

export const query = graphql`
  fragment LargeLinkCta on DatoCmsLargeLinkCta {
    __typename
    id: originalId
    link {
      ...LinkCollection
    }
    externalUrl
    backgroundColor
    text
  }
`;
