import React from 'react';
import GallerySection from 'sections/gallery-section';
import Accordion from 'sections/accordion';
import GraphicHeader from 'sections/graphic-header';
import ImageAndTextCta from 'sections/image-text-cta';
import LargeLink from 'sections/large-link';
import ListSection from 'sections/list-section';
import NewsFeed from 'sections/news-feed';
import NewsSelection from 'sections/news-selection';
import ServiceSection from 'sections/service-section';
import Spacing from 'sections/spacing';
import LargeAccordion from 'sections/large-accordion';
import PostList from 'sections/post-list';
import LogotypesSection from 'sections/logotypes-section';

export type SpacingType = GatsbyTypes.DatoCmsSpacing & {
  __typename: 'DatoCmsSpacing';
};
export type ImageAndTextCtaType = GatsbyTypes.DatoCmsImageAndTextCta & {
  __typename: 'DatoCmsImageAndTextCta';
};
export type NewsFeedType = GatsbyTypes.DatoCmsNewsFeed & {
  __typename: 'DatoCmsNewsFeed';
};
export type ServiceSectionType = GatsbyTypes.DatoCmsServiceSection & {
  __typename: 'DatoCmsServiceSection';
};
export type GraphicHeaderType = GatsbyTypes.DatoCmsGraphicHeader & {
  __typename: 'DatoCmsGraphicHeader';
};
export type ListSectionType = GatsbyTypes.DatoCmsListSection & {
  __typename: 'DatoCmsListSection';
};
export type LargeLinkCtaType = GatsbyTypes.DatoCmsLargeLinkCta & {
  __typename: 'DatoCmsLargeLinkCta';
};
export type AccordionType = GatsbyTypes.DatoCmsAccordion & {
  __typename: 'DatoCmsAccordion';
};
export type LargeAccordionType = GatsbyTypes.DatoCmsLargeAccordion & {
  __typename: 'DatoCmsLargeAccordion';
};
export type NewsSelectionType = GatsbyTypes.DatoCmsNewsSelection & {
  __typename: 'DatoCmsNewsSelection';
};
export type GalleryType = GatsbyTypes.GalleryFragment & {
  __typename: 'DatoCmsGallery';
};
export type PostListType = GatsbyTypes.PostListFragment & {
  __typename: 'DatoCmsPostList';
};
export type LogotypesSectionType = GatsbyTypes.LogotypesSectionFragment & {
  __typename: 'DatoCmsLogotypesSection';
};

export type BlockType =
  | SpacingType
  | ImageAndTextCtaType
  | NewsFeedType
  | NewsSelectionType
  | GalleryType
  | ServiceSectionType
  | GraphicHeaderType
  | ListSectionType
  | LargeLinkCtaType
  | AccordionType
  | LargeAccordionType
  | PostListType
  | LogotypesSectionType;

type RenderBlocksProps = {
  data?: BlockType[];
};

const RenderBlocks: React.FC<RenderBlocksProps> = ({ data = [] }) => (
  <>
    {data.map(({ id, __typename, ...block }) => {
      switch (__typename) {
        case 'DatoCmsSpacing':
          return <Spacing key={id} {...(block as GatsbyTypes.DatoCmsSpacing)} />;
        case 'DatoCmsImageAndTextCta':
          return <ImageAndTextCta key={id} {...(block as GatsbyTypes.DatoCmsImageAndTextCta)} />;
        case 'DatoCmsNewsFeed':
          return <NewsFeed key={id} {...(block as GatsbyTypes.DatoCmsNewsFeed)} />;
        case 'DatoCmsServiceSection':
          return <ServiceSection key={id} {...(block as GatsbyTypes.DatoCmsServiceSection)} />;
        case 'DatoCmsLargeLinkCta':
          return <LargeLink key={id} {...(block as GatsbyTypes.DatoCmsLargeLinkCta)} />;
        case 'DatoCmsGraphicHeader':
          return <GraphicHeader key={id} {...(block as GatsbyTypes.DatoCmsGraphicHeader)} />;
        case 'DatoCmsListSection':
          return <ListSection key={id} {...(block as GatsbyTypes.DatoCmsListSection)} />;
        case 'DatoCmsAccordion':
          return <Accordion key={id} {...(block as GatsbyTypes.DatoCmsAccordion)} />;
        case 'DatoCmsLargeAccordion':
          return <LargeAccordion key={id} {...(block as GatsbyTypes.DatoCmsLargeAccordion)} />;
        case 'DatoCmsNewsSelection':
          return <NewsSelection key={id} {...(block as GatsbyTypes.DatoCmsNewsSelection)} />;
        case 'DatoCmsGallery':
          return <GallerySection key={id} {...(block as GatsbyTypes.GalleryFragment)} />;
        case 'DatoCmsPostList':
          return <PostList key={id} {...(block as GatsbyTypes.PostListFragment)} />;
        case 'DatoCmsLogotypesSection':
          return <LogotypesSection key={id} {...(block as GatsbyTypes.LogotypesSectionFragment)} />;

        default:
          return <div key={id}>{__typename}</div>;
      }
    })}
  </>
);

export default RenderBlocks;
