// extracted by mini-css-extract-plugin
export var large = "service-section-module--large--98k1-";
export var section = "service-section-module--section--RHHio";
export var stickyContainer = "service-section-module--stickyContainer--0veph";
export var stickyItemWrapper = "service-section-module--stickyItemWrapper--nwKrk";
export var stickyItem = "service-section-module--stickyItem--g5xMd";
export var placeholder = "service-section-module--placeholder--kio85";
export var mockup = "service-section-module--mockup--BiNvj";
export var stickyItemArrow = "service-section-module--stickyItemArrow--Z+NCQ";
export var items = "service-section-module--items--XOuTp";
export var itemsWhite = "service-section-module--itemsWhite--2jPzn";
export var serviceItem = "service-section-module--serviceItem--ZZoMh";
export var content = "service-section-module--content--W5q7K";
export var link = "service-section-module--link--OEw0p";
export var image = "service-section-module--image--43KpI";
export var imageItem = "service-section-module--imageItem--nBd2v";
export var imageFigure = "service-section-module--imageFigure--ni1Ac";
export var imageFigureLeft = "service-section-module--imageFigureLeft--MwvIu";
export var serviceItemImgLeft = "service-section-module--serviceItemImgLeft--VOThL";