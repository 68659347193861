import clsx from 'clsx';
import Arrow from 'components/arrow';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Link, { LinkType } from 'components/link';
import MatrixFigure from 'components/matrix-figure';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './image-text-cta.module.scss';

const ImageAndTextCta: React.FC<GatsbyTypes.DatoCmsImageAndTextCta> = ({
  link,
  image,
  text,
  linkText,
  showFigure,
  textBackgroundColor,
  ...props
}) => {
  const colConfig: ColumnResponsiveSizes = {
    xl: {
      width: 10,
      offset: 1,
    },
    lg: {
      width: 10,
      offset: 1,
    },
    md: {
      width: 10,
      offset: 1,
    },
    sm: {
      width: 10,
      offset: 1,
    },
  };

  const textColumnClasses = clsx(
    styles.textColumn,
    textBackgroundColor && (styles as never)[`textColumnBG${textBackgroundColor}`]
  );
  const imageColumnClasses = clsx(
    styles.imageColumn,
    textBackgroundColor && (styles as never)[`textColumnBG${textBackgroundColor}`]
  );

  return (
    <Section className={styles.wrapper} {...props}>
      <Column {...colConfig}>
        <Column className={imageColumnClasses} xl={6} lg={6} md={6} sm={6}>
          <Image className={styles.image} {...image} />
        </Column>
        <Column className={textColumnClasses} xl={6} lg={6} md={6} sm={6}>
          {showFigure && <MatrixFigure className={styles.textColumnFigure} size={'small'} />}
          <Link className={styles.link} link={link as LinkType}>
            <h2>{text}</h2>
            <p className={styles.linkText}>
              {linkText} <Arrow className={styles.linkArrow} dir={'right'} />
            </p>
          </Link>
        </Column>
      </Column>
    </Section>
  );
};

export default ImageAndTextCta;

export const query = graphql`
  fragment ImageAndTextCta on DatoCmsImageAndTextCta {
    __typename
    id: originalId
    image {
      ...Image
    }
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
    textBackgroundColor
    text
    linkText
    showFigure
    link {
      ...LinkCollection
    }
  }
`;
