import Column from 'components/column';
import Section from 'components/section';
import { useMeasure } from 'react-use';
import { animated, useSpring } from 'react-spring';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import React from 'react';

import * as styles from './accordion.module.scss';

type AccordionItemType = GatsbyTypes.DatoCmsAccordionItem & {
  open: boolean;
  onClick: (id: string) => void;
};

const AccordionItem: React.FC<AccordionItemType> = ({ id, title, text, open, onClick }) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [contentHeight, setContentHeight] = React.useState<number>(height);

  const style = useSpring({
    opacity: open ? 1 : 0,
    height: open ? contentHeight : 0,
    config: {
      tension: 700,
      friction: 50,
    },
  });

  React.useEffect(() => {
    const setHeights = () => {
      setContentHeight(height);
    };

    window.addEventListener('resize', setHeights);
    setHeights();

    return () => {
      window.removeEventListener('resize', setHeights);
    };
  }, [height, open]);

  return (
    <div className={styles.accordionItem}>
      <Column
        className={clsx(styles.accordionItemColumn, styles.accordionItemColumnTitle)}
        onClick={() => onClick(id)}
      >
        <h2 className={styles.accordionItemTitle}>{title}</h2>
        <span className={clsx(styles.buttonExpand, open && styles.buttonExpandOpen)} />
      </Column>
      <Column className={styles.accordionItemColumn} xl={8} lg={8} md={8} sm={8} xs={10}>
        <animated.div style={style} className={styles.accordionItemContent}>
          <div ref={ref}>
            <div
              className={styles.accordionItemContentInner}
              dangerouslySetInnerHTML={{ __html: text as string }}
            />
          </div>
        </animated.div>
      </Column>
    </div>
  );
};

const Accordion: React.FC<GatsbyTypes.DatoCmsAccordion> = ({ title, items = [], ...props }) => {
  const [isOpen, setIsOpen] = React.useState<string>(items[0]?.id ?? '');

  return (
    <Section
      className={clsx(styles.section, (styles as never)[`sectionBG${props.backgroundColor}`])}
      {...props}
    >
      <Column className={styles.accordionContainerTitle} xl={3} lg={3} md={3}>
        <h2>{title}</h2>
      </Column>
      <Column className={styles.accordionContainer} xl={{ width: 8, offset: 1 }} lg={9} md={9}>
        {items.map(
          (item, index) =>
            item && (
              <AccordionItem
                key={item.id}
                open={isOpen === item.id}
                onClick={(id) => setIsOpen(id)}
                {...item}
              />
            )
        )}
      </Column>
    </Section>
  );
};

export default Accordion;

export const query = graphql`
  fragment Accordion on DatoCmsAccordion {
    __typename
    id: originalId
    title
    items {
      id: originalId
      title
      text
    }
    backgroundColor
  }
`;
