import React from 'react';
import Section from 'components/section';
import { graphql } from 'gatsby';
import CardCarousel, { CardCarouselHandle, OnPageChangeArgs } from 'sections/card-carousel';
import clsx from 'clsx';
import Column from 'components/column';
import Arrow from 'components/arrow';

import * as styles from './news-selection.module.scss';
import LayoutContext from 'layouts/reducer';

const NewsSelection: React.FC<GatsbyTypes.DatoCmsNewsSelection> = ({
  items = [],
  text,
  textColor,
  ...props
}) => {
  const [{ currentPage, totalPages, canScrollNext, canScrollPrev }, setCarouselDetails] =
    React.useState<OnPageChangeArgs>({
      currentPage: 0,
      totalPages: 0,
    });
  const { state } = React.useContext(LayoutContext);
  const ref = React.useRef<CardCarouselHandle>(null);

  return (
    <Section
      className={clsx(
        styles.newsSelection,
        (styles as never)[`newsFeedBG${props.backgroundColor}`]
      )}
      {...props}
    >
      <Column xl={3} lg={3} md={4} sm={4}>
        <div
          className={clsx(
            styles.newsSelectionContent,
            (styles as never)[`newsSelectionContentTC${textColor}`]
          )}
          dangerouslySetInnerHTML={{ __html: text as string }}
        />
      </Column>
      <Column className={styles.newsSelectionCarousell} xl={9} lg={9} md={8} sm={8}>
        <CardCarousel
          ref={ref}
          perPage={3}
          scrollCount={state.isLarge ? 3 : state.isSmall ? 2 : 1}
          onPageChange={setCarouselDetails}
          backgroundColor={props.backgroundColor}
          data={items.map((post, index) => ({
            id: post?.id,
            background: index % 2 === 0 ? 'dark' : 'light',
            featuredImage: post?.featuredImage,
            title: post?.title || '',
            metaText: {
              text: post?.category?.title || '',
              date: post?.meta?.publishedAt,
            },
            link: {
              id: post?.id,
              slug: post?.slug,
              model: {
                apiKey: post?.model?.apiKey || '',
              },
            },
          }))}
        />
        <Column className={styles.column}>
          {totalPages > 1 && (
            <div className={styles.scrollContainer}>
              <button
                className={styles.scrollButton}
                disabled={!canScrollPrev}
                onClick={ref.current?.scrollPrev}
              >
                <Arrow dir={'left'} />
              </button>
              {currentPage + 1}/{totalPages}
              <button
                className={styles.scrollButton}
                disabled={!canScrollNext}
                onClick={ref.current?.scrollNext}
              >
                <Arrow dir={'right'} />
              </button>
            </div>
          )}
        </Column>
      </Column>
    </Section>
  );
};

export default NewsSelection;

export const query = graphql`
  fragment NewsSelection on DatoCmsNewsSelection {
    __typename
    id: originalId
    backgroundColor
    topBackgroundColor
    topBackgroundOffset
    text
    textColor
    items {
      id: originalId
      title
      slug
      meta {
        publishedAt: firstPublishedAt(formatString: "YYYY-MM-DD")
      }
      category {
        title
      }
      model {
        apiKey
      }
      featuredImage {
        isImage
        gatsbyImageData(placeholder: DOMINANT_COLOR)
        url
        alt
        mimeType
      }
    }
  }
`;
