import React from 'react';
import Gallery from 'components/gallery';
import Section, { SectionProps } from 'components/section';
import MatrixFigure from 'components/matrix-figure';

import * as styles from './gallery-section.module.scss';

type GalleryType = Omit<GatsbyTypes.GalleryFragment, '__typename' | 'id'> &
  Pick<SectionProps<'section'>, 'backgroundColor' | 'topBackgroundColor' | 'topBackgroundOffset'>;

const GallerySection: React.FC<GalleryType> = ({ slides = [], showFigure, ...section }) => {
  return (
    <Section className={styles.section} {...section}>
      {showFigure && <MatrixFigure className={styles.figure} size={'small'} />}
      <Gallery slides={slides} />
    </Section>
  );
};

export default GallerySection;
