// extracted by mini-css-extract-plugin
export var section = "accordion-module--section--XDRTV";
export var accordionContainer = "accordion-module--accordionContainer--fRC7o";
export var accordionContainerTitle = "accordion-module--accordionContainerTitle--4VWNC";
export var accordionItem = "accordion-module--accordionItem--O5Ljm";
export var accordionItemColumn = "accordion-module--accordionItemColumn--IRJd7";
export var accordionItemColumnTitle = "accordion-module--accordionItemColumnTitle--WNmxR";
export var accordionItemTitle = "accordion-module--accordionItemTitle--xkC9X";
export var accordionItemContent = "accordion-module--accordionItemContent--gvzSB";
export var accordionItemContentInner = "accordion-module--accordionItemContentInner--p1fN5";
export var sectionBGmint = "accordion-module--sectionBGmint--n8hBg";
export var sectionBGwhite = "accordion-module--sectionBGwhite--BceU5";
export var buttonExpand = "accordion-module--buttonExpand--NA1d4";
export var sectionBGblue = "accordion-module--sectionBGblue--iOhTc";
export var buttonExpandOpen = "accordion-module--buttonExpandOpen--JHkd3";